<!-- @format -->

<template>
  <BasicLayout ref="layout">
    <template #sidebar>
      <div class="flex w-full p-2 pt-0 border border-dashed border-sky-700 border-t-0 rounded-b-3xl">
        <div class="flex w-80 p-4 bg-gray-50 rounded-b-2xl">
          <div class="flex-col">
            <!--             <div class="flex p-1 cursor-pointer text-amber-500 hover:text-amber-600">
              <div>
                <fa-icon icon="star"></fa-icon>
              </div>
              <div class="pl-3">Recommended for you</div>
            </div> -->
            <div class="flex p-1 cursor-pointer text-primary hover:text-primary-focus" @click="initPage">
              <div>
                <fa-icon icon="globe"></fa-icon>
              </div>
              <div class="pl-3">All Categories</div>
            </div>
            <div class="flex p-1 cursor-pointer text-sky-400 hover:text-sky-600">
              <div>
                <fa-icon icon="star"></fa-icon>
              </div>
              <div class="pl-3">Popular Subjects</div>
            </div>
            <!--             <div class="flex p-1 cursor-pointer text-pink-600 hover:text-pink-800">
              <div>
                <fa-icon icon="heart"></fa-icon>
              </div>
              <div class="pl-3">Your Favourites</div>
            </div> -->

            <div class="flex p-1 cursor-pointer text-blue-900 hover:text-lime-600" v-for="cat in categories"
              :key="cat.id" @click="onCategorySelected(cat)">
              <div>
                <fa-icon icon="angles-right"></fa-icon>
              </div>
              <div class="pl-3">{{ cat.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="p-4">
      <div class="grid grid-cols-4 gap-2">
        <div v-for="course in courses" :key="course.id">
          <CartCourseTile :course="course" @findOut="onFindOutClicked" @signUp="onSignUpClick"></CartCourseTile>
        </div>
      </div>
    </div>
    <CourseInfoSlider v-model:showOn="courseInfoOpen" :course="selectedCourse" @onViewSchedule="onSignUpClick">
    </CourseInfoSlider>

    <SignupSlider @onProceedRegister="proceedRegister" v-model:showOn="showEnrollmentForm" :courserun="selected"
      :isLoading="isRegistering" :customFields="customFields" :sponsorType="registrationForm.sponsorType"
      :activeTab="enrolmentTab"></SignupSlider>

    <CourserunListSlider v-model:showOn="runListOpen" :course="selectedCourse" @onSelectRun="onCourserunSelected">
    </CourserunListSlider>
  </BasicLayout>
</template>

<script>
import { defineComponent } from "vue";
import { initMarketplace, getCategoryCourses } from "@/data/courses.data.js";
import BasicLayout from "@/layouts/BasicLayout";
import CartCourseTile from "@/components/CartCourseTile.vue";
import CourseInfoSlider from "./CourseInfoSlider.vue";
import SignupSlider from "./SignupSlider.vue";
import CourserunListSlider from "./CourserunListSlider.vue";
import { mapGetters } from 'vuex';
export default defineComponent({
  data() {
    return {
      courses: [],
      categories: [],
      filterCategory: null,
      courseInfoOpen: false,
      runListOpen: false,
      selectedCourse: null,
      moreInfoWindow: false,
      registrationForm: {
        showForm: false,
        sponsorType: null,
        sponsor: null,
      },
      showEnrollmentForm: false,
      enrolmentTab: 1,
      selected: null,
      isRegistering: false,
      customFields: null,
      guestSignup: false,
    };
  },
  async created() {
    this.initPage();
    /* const [error, data] = await initMarketplace();
    if (error) console.error(error);
    else {
      this.courses = data.courses;
      this.categories = data.categories;
    } */
  },
  computed: {
    ...mapGetters([
      'getUserData'
    ])
  },
  watch: {    
    getUserData: function (v) {
      if (v && this.guestSignup) {
        console.log('proceed signup');
        this.runListOpen = true;
      } else {
        console.log('nada');
      }
    }
  },
  methods: {
    async initPage() {
      const [error, data] = await initMarketplace();
      if (error) console.error(error);
      else {
        this.courses = data.courses;
        this.categories = data.categories;
      }
    },

    async onCategorySelected(cat) {
      console.log(cat);
      this.filterCategory = cat;
      const courses = await getCategoryCourses(cat.id);

      this.courses = courses.data
    },
    onCourserunSelected(courserun) {
      this.selected = courserun;
      this.selectSponsorship();
    },
    proceedRegister(data) {
      console.log(data);
      /*  var form = this.$inertia.form({
         courserun: this.selected.id,
         enrollment_data: data,
       });
       form.post(route("cart.tryregister", { id: this.selected.id }), {
         preserveScroll: true,
         onSuccess: () => (showEnrollmentForm = false),
       }); */
    },
    onFindOutClicked(course) {
      this.selectedCourse = course;
      this.courseInfoOpen = true;
    },
    onSignUpClick(course) {
      this.selectedCourse = course;queueMicrotask
      if (!this.getUserData) {
        this.guestSignup = true;
        this.$refs.layout.login()
      } else {
        this.runListOpen = true;
      }
    },
    openPreview(item) {
      this.selected = item;
      this.customFields = this.custom_fields;
      this.moreInfoWindow = true;
    },
    onRegister() {
      this.showEnrollmentForm = true;
    },
    onSignUpCourse() {
      this.moreInfoWindow = false;
      this.selectSponsorship();
    },
    async selectSponsorship() {
      let ask = await this.$swal.fire({
        title: "Sponsorship Type",
        text: "On which sponsorship type you want to enroll ?",
        showCancelButton: false,
        showDenyButton: true,
        denyButtonText: "Individual",
        confirmButtonText: "Employer",
      });

      if (ask.isConfirmed) {
        this.registrationForm.sponsorType = "EMPLOYER";
        this.onRegister({});
      }

      if (ask.isDenied) {
        this.registrationForm.sponsorType = "INDIVIDUAL";
        this.onRegister({});
      }
    },
  },
  components: {
    BasicLayout,
    CartCourseTile,
    CourseInfoSlider,
    SignupSlider,
    CourserunListSlider,
  },
});
</script>
